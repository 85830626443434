import React from 'react';
import { connect } from 'react-redux';
import config from '../../../config';
import ReactNotifications, { store } from 'react-notifications-component';
import Videojs from '../video/video.js';
import common from '../../../common';
import ReactPlayer from 'react-player/youtube'
import actions from '../../../redux/actions';
import 'video.js/dist/video-js.css';

const surveyjson = config?.surveyjson;
const surveydata = JSON.parse(surveyjson);

class VideoPlayer2 extends React.Component {
	constructor(props) {
    super(props);
		this.state = {
			url: this.props.url,
      videoJsOptions: {},
      wChange: this.props.wChange,
			loading: true,
      video_played_time:0,
      wKey:0,
      is_youtube:0,
      youtubeString:null,
      content_id:this.props.content_id,
      ref_id:this.props.ref_id,
      level:this.props.level,
      content_type:this.props.content_type,
      content_subtype:this.props.content_subtype,
      subject:this.props.subject_id,
      current_pointer:this.props.current_pointer_video,
      playing:true,
      is_faculty:localStorage.getItem("is_faculty") == "true"? true:false,
      is_admin:localStorage.getItem("is_admin") == "true"? true:false,

      saveVisibilityTypeVideo:surveydata?.response?.visibility_rules?.video ? surveydata?.response.visibility_rules?.video : '',
      saveVideoElement:document.getElementById('widget4'),
      saveVideoElementId:'',
      videoSurvayDialogSkipByUser:false,
      videoSurvayDialogClosedByUser:false,
      isSurveyInputChecked:false,
      videoSurvayDialogOpen:false,
      videoSurvayThanksDialogOpen:false,
      survey_id:'',
      responses: [] ,
      center_id:localStorage.getItem('center_id')
    }
  }

  componentDidMount() {
    this.ref = React.createRef(); 
    console.log(this.state.url)
    //const idVal=this.state.wKey.replace(/\s/g,'');  
  	this.setState({
  		videoJsOptions: {
  			autoplay: true,
        preload: 'auto',
  			responsive: true,
  			controls: true,
  			controlBar: { volumePanel: { inline: true }, pictureInPictureToggle: true },
  			fluid: true,
  			playbackRates: [0.5, 1, 1.25, 1.5, 2],
  			techCanOverridePoster: true,
  			textTrackSettings: true,
  			sources: [
  			  {
  			    src:common.addFileUrl(this.props.url),
  			    type: this.checkType(this.props.url),
  			  },
        ]
  		},
      loading: false,
      url: this.props.url,
      meetcondition:false
  	})

    if(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/.test(this.state.url)) {
      
      let stringUrl = this.state.url.split("=")[1] ;
      this.setState({is_youtube :1,youtubeString:stringUrl})
      //setInterval(this.addTime,1000)
    }
    window.addEventListener('beforeunload', this.saveState);


    if (config?.surveyAllowedOrg_ids?.includes(parseInt(this.state.center_id))){
      console.log("ID", this.state.center_id, "is allowed. Proceed with the code.");
      if (this.state.saveVisibilityTypeVideo !== '') {
        setTimeout(() => {
          this.handleProgress();
        }, 1000);
      }
    }else{
      console.log("ID", this.state.center_id, "is not allowed")
    }
  



  }


  handleProgress  = (progressState) => {

    let getSurveySubmitStatus = localStorage.getItem(this.state.content_id+"survaySubmitted"); 
    console.log("Survey Submit Status:", getSurveySubmitStatus);

    if(config?.surveyAllowedOrg_ids?.includes(parseInt(this.state.center_id)) && getSurveySubmitStatus == null){
    const player = this.ref.current;
    const {content_id,responses} = this.state;
    const { visibility_rules } = surveydata?.response || {};
    const { video } = visibility_rules || {};
    const { played } = video || {};
    const conditions = surveydata?.response?.visibility_rules?.video?.condition || {};
    //console.log("played", played);
    //console.log("conditions", conditions);

    if (!this.ref.current) {
      console.log('Video has not reached');
      return;
    }

    const duration = player.getDuration();
    const currentTime = player.getCurrentTime();
    const completionPercentage = (currentTime / duration) * 100;
    // console.log("duration",duration)
    // console.log("currentTime",currentTime)
    // console.log("completionPercentage",completionPercentage)

   
    const conditionString = `${completionPercentage} ${conditions} ${played}`; 
  
    if (eval(conditionString)) {
      
      this.setState({meetcondition:true});
      //console.log("meet condition true");
  
      if (getSurveySubmitStatus === null && !this.state.videoSurvayDialogClosedByUser && !this.state.videoSurvayDialogSkipByUser) {
        if(this.state.videoSurvayDialogOpen){
          this.ref.current.getInternalPlayer().pauseVideo()
          this.setState({ videoSurvayDialogOpen: true });
          //console.log("Enter case 1 if");
        }else{
          this.ref.current.getInternalPlayer().playVideo();
          this.setState({ videoSurvayDialogOpen: true });
          //console.log("Enter case 1 else");
        }
      } else if (getSurveySubmitStatus !== null && !this.state.videoSurvayDialogSkipByUser && !this.state.videoSurvayDialogClosedByUser) {
        
          if (this.ref.current) {
            setTimeout(() => { this.ref.current.getInternalPlayer().playVideo()}, 500);
            this.setState({ videoSurvayDialogOpen: true });
            //console.log("Enter case 2");
          }
        
      } else if (getSurveySubmitStatus === null && !this.state.videoSurvayDialogSkipByUser && this.state.videoSurvayDialogClosedByUser) {
        // if (this.state.videoSurvayDialogOpen && myPlayer.paused()) {
        //   setTimeout(() => { myPlayer.play(); }, 500);
        //   console.log("Enter case 3");
        // }
      } else if (getSurveySubmitStatus === null && this.state.videoSurvayDialogSkipByUser && this.state.videoSurvayDialogOpen) {
        //console.log("Enter case 4");
      }
    }else{
      //console.log("meet condition false");
      this.setState({meetcondition:false})
    }

  }
    
  }

  checkDialogReopen = () => {
    // If dialog is closed, reopen it
      //  console.log("11111111111111");
      // console.log("this.state.meetcondition");
    if (!this.state.videoSurvayDialogOpen) {
      // console.log("Not this.state.videoSurvayDialogOpen");
      // console.log(!this.state.videoSurvayDialogOpen);
      this.setState({ videoSurvayDialogOpen: true });
    }
  
    if (this.state.videoSurvayDialogOpen) {
      // console.log("Yes this.state.videoSurvayDialogOpen");
      // console.log(this.state.videoSurvayDialogOpen);
      this.ref.current.getInternalPlayer().pauseVideo();
      
    }
    if(!this.state.meetcondition){
      this.setState({ videoSurvayDialogOpen: false });
      this.setState({ videoSurvayDialogClosedByUser: false });
      // console.log("222222222222222222222");
      this.ref.current.getInternalPlayer().playVideo();
    }
  
  };

  onSumbitSurvay = async () => {
    const response = await this.props.userSurvayCompletion(surveydata?.response.survey_id,this.state.content_id,this.state.responses);
      if(response !== undefined){
        this.setState({videoSurvayDialogOpen:false});
        this.setState({videoSurvayThanksDialogOpen:true});
        localStorage.setItem(this.state.content_id+"survaySubmitted",1);
        // console.log("response.error");
        // console.log(response.error);
      }
    }
  
    videoSurvayDialogClose = () => {
      this.setState({ videoSurvayDialogOpen: false });
      this.setState({ videoSurvayDialogClosedByUser: true });
      if (this.ref.current) {
        if(this.state.meetcondition){
      setTimeout(() => {
        if (this.ref.current) {
          console.log("Ensure that the video resumes playing");
          this.ref.current.getInternalPlayer().playVideo(); // Ensure that the video resumes playing
        }
      }, 500);
      setTimeout(() => {
        if (this.ref.current) {
          if (this.ref.current.getInternalPlayer()?.pauseVideo()) {
            this.checkDialogReopen();
          }
        }
      }, 60000);
    }
    }else {
      console.error("saveVideoElement is null or undefined");
    }
  }
    
    onSurvaySkip = () => {
      this.setState({videoSurvayDialogOpen:false});
      localStorage.setItem(this.state.content_id+"survaySubmitted",1);
      this.setState({ videoSurvayDialogSkipByUser: true });
      setTimeout(() => {
        this.ref.current.getInternalPlayer().playVideo()
      }, 500);
    };
  
    videoSurvayThanksDialogClose = () => {
      this.setState({videoSurvayThanksDialogOpen:false});
      setTimeout(() => {
        this.ref.current.getInternalPlayer().playVideo();
      }, 500);
      
      
    };

    handleResponseChange = (questionId, response, time) => {
      // Check if a response for the given question already exists
      const existingResponseIndex = this.state.responses.findIndex(res => res.question_id === questionId);
    
      // If a response already exists, update it; otherwise, add a new response
      if (existingResponseIndex !== -1) {
        const updatedResponses = [...this.state.responses];
        updatedResponses[existingResponseIndex] = { question_id: questionId, response: response, time: time };
        this.setState({
          responses: updatedResponses,
          isSurveyInputChecked: true
        });
      } else {
        const newResponse = { question_id: questionId, response: response, time: time };
        this.setState(prevState => ({
          responses: [...prevState.responses, newResponse],
          isSurveyInputChecked: true
        }));
      }
    };



  saveState = (ev) => {
    ev.preventDefault();
    this.consumedVideo();
    return ev.returnValue = 'Are you sure you want to close?';
  }

  consumedVideo = async() => {

    const {content_id,ref_id,level,content_type,content_subtype,subject} = this.state;
    
    if(!this.state.is_faculty && !this.state.is_admin){
      const response = await this.props.userContentCompletion(content_id,ref_id,level,content_type,content_subtype,parseInt(this.player.getCurrentTime()),parseInt(this.player.getDuration()),parseInt(this.player.getCurrentTime()),parseInt(this.state.current_pointer),parseInt(subject));
    }
  }

  addTime = () =>{
    let time = this.player?.getCurrentTime();
    console.log("jjjg"+time)
  }

  componentWillUnmount() {
    if (this.player) {
      this.consumedVideo();
    }
    document.removeEventListener('beforeunload', this.saveState);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.wChange !== this.props.wChange) {
      //const idVal=this.state.wKey.replace(/\s/g,''); 
      this.setState({video_played_time:localStorage.getItem("video_played_time")});
      this.setState({ wChange: this.props.wChange});
    } 

  	if(prevProps.url !== this.props.url) {
  		this.setState({
  			videoJsOptions: {
  				autoplay: true,
          preload: 'auto',
  				responsive: true,
  				controls: true,
  				controlBar: { volumePanel: { inline: true }, pictureInPictureToggle: true },
  				fluid: true,
  				playbackRates: [0.5, 1, 1.25, 1.5, 2],
  				techCanOverridePoster: false,
  				textTrackSettings: true,
  				sources: [
  				  {
  				    src:common.addFileUrl(this.props.url),
  				    type: this.checkType(this.props.url),
  				  },
          ]
  			},
        url: this.props.url
  		})
  	}
  }

	checkType(url) {
		if(url.substring(url.length - 4, url.length).toLowerCase() === 'm3u8') {
			return 'application/x-mpegURL'
		}
		else {
			return 'video/mp4'
		}
	}

  ref = player => {
    this.player = player
  }

  handlePlay = () => {
    console.log('onPause')
    console.log("getCurrentTime"+this.player.getCurrentTime())

    console.log(this.state.current_pointer)
    this.player.seekTo(this.state.current_pointer)
    //let time = this.player.getCurrentTime() + 10;
    //this.player.seekTo(time)
    this.setState({ playing: true })
  }
    
	render() {
    const { isSurveyInputChecked } = this.state;
		if(this.state.loading === true) {
			return (
				<div>
				</div>
			)
		}
    if(this.state.is_youtube === 1) {
      localStorage.setItem("checkExternalFile",true)
      return (
        <div className='YoutubePlayer'>
          <ReactNotifications/>
          {this.state.videoSurvayThanksDialogOpen}
           <div className={this.state.videoSurvayThanksDialogOpen ? 'videoSurvayThankuDialog open': 'videoSurvayThankuDialog'}>
              <div className='videoSurvayThankuDialogContainer'>
                <h3>Thank you for your precious feedback</h3>
                <p>Your opinion is important to us. Your answers will be used for improving our services to you.</p>
                <div className='formActions'><button onClick={this.videoSurvayThanksDialogClose}>Close</button></div>
            </div>
          </div>

        <div  className={this.state.videoSurvayDialogOpen ? 'videoSurvayDialog open': 'videoSurvayDialog'}>
            <div className='videoSurvayContainer'>
              <div className='dialogClose' onClick={this.videoSurvayDialogClose}><span><i className='fa fa-times'></i></span></div>
              <h3>Survey Form</h3>
              {/* {JSON.stringify(surveydata?.response?.questions)} */}
             
              <div className='contentWrapper'>
                  <ol>
                  {surveydata?.response?.questions && surveydata?.response?.questions.length > 0 && surveydata?.response?.questions.map((data,index) =>
                      <li key={data.question_id}> {/* Assuming question_id is unique */}
                          <div className='question'>{data.question}</div>
                          <div className='rating'>
                          {data?.options.map((dataoptions, index) =>
                                  <label key={index}>
                                      <input 
                                          type="radio" 
                                          name={`question${data.question_id}`} 
                                          value={dataoptions} 
                                          onChange={e => this.handleResponseChange(data.question_id, parseInt(e.target.value), null)} 
                                      />
                                      <span>{dataoptions}</span>
                                  </label>
                              )}
                          </div>
                      </li>
                  )}
                      
                  </ol>
                  <div className='formActions'><button  onClick={this.onSumbitSurvay} disabled={!isSurveyInputChecked}>Submit</button><span onClick={this.onSurvaySkip}>Skip</span></div>
              </div>
            </div>

        </div>
        <ReactPlayer height="100%" width="100%" ref={this.ref} url={this.state.url} controls={true} volume={1} muted={true} playbackRate={1} playing={this.state.playing} onLoaded={this.handlePlay} onProgress={this.handleProgress} config={{ file: { attributes: { controlsList: 'nodownload' } } }}/>
        </div>                    
      )
    }
		else {
      localStorage.setItem("checkExternalFile",false)
			return (
        <Videojs content_id={this.state.content_id} level={this.state.level} ref_id={this.state.ref_id} subject_id={this.state.subject} content_type={this.state.content_type} content_subtype={this.state.content_subtype} current_pointer={this.state.current_pointer} wChange={this.state.wChange} wVal={this.state.video_played_time} vPlayed={this.state.video_played_time}  {...this.state.videoJsOptions} />
        
    	);
		}
	}
}


const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  const { userContentCompletion,userSurvayCompletion} = actions;
  return {
    userContentCompletion: (content,ref_id,level,content_type,content_subtype,page_number,max_page_no,duration_taken,previous_page_number,subject) => dispatch(userContentCompletion(content,ref_id,level,content_type,content_subtype,page_number,max_page_no,duration_taken,previous_page_number,subject)),
    userSurvayCompletion: (survey_id,content_id,responses) => dispatch(userSurvayCompletion(survey_id,content_id,responses)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer2);